import React from "react";
import ReactDOM from "react-dom";
import { MoralisProvider } from "react-moralis";
import { MoralisDappProvider } from "./providers/MoralisDappProvider/MoralisDappProvider";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/SpaceMono-Regular.ttf";
import "./fonts/SpaceMono-Italic.ttf";
import "./fonts/SpaceMono-Bold.ttf";
import "./fonts/SpaceMono-BoldItalic.ttf";
import "./fonts/Montserrat-ExtraBoldItalic.ttf";

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

//console.log(APP_ID);
//console.log(SERVER_URL);

ReactDOM.render(
  <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
    <MoralisDappProvider>
      <App />
    </MoralisDappProvider>
  </MoralisProvider>,
  document.getElementById("root")
);

reportWebVitals();
