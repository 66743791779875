import React, { useState } from "react";
import ContactForm from "../Contact/ContactForm";
import About from "../Misc/About";
import Connect from "../DAO/Connect";
import { Text, Image, Link, Button, Center, Box } from "@chakra-ui/react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

export default function Nav() {
  let [isOpen, setIsOpen] = useState(false);
  let [link, setLink] = useState("");
  let [transitionClass, setCSS] = useState("");
  let [transitionLink, setTransLink] = useState(null);

  let [active, setActive] = useState("");

  let component;

  if (link === "contact") {
    component = <ContactForm />;
  } else if (link === "connect") {
    component = <Connect />;
  } else if (link === "about") {
    component = <About />;
  } else {
  }

  // check if
  const transitionCheck = () => {
    setLink(transitionLink);
    setIsOpen(!isOpen);
    setActive(true);
  };

  const handleClick1 = (props) => {
    if (link === "") {
      setIsOpen(!isOpen);
      setActive(true);
    }

    if (link === props.target.name) {
      // close
      setLink("");
      setActive(false);
      setCSS("");
    } else {
      // not closed
      if (props.target.name !== link && active) {
        // link = previous
        // props.target.name = to load

        console.log("2", link);
        console.log("2", props.target.name);
        setCSS(link);

        setActive(false);

        transitionLink = props.target.name;
        setTransLink(props.target.name);
      } else {
        // link = previous
        // props.target.name = to load

        console.log("3", link);
        console.log("3", props.target.name);

        setLink(props.target.name);
        setActive(true);
        setTransLink(null);
        transitionLink = null;
      }
    }

    if ("home" === props.target.name) {
      setLink("");
      setActive(false);
      setIsOpen(false);
      setTransLink(null);
      transitionLink = null;
    } else {
      console.log("1", link);
      console.log("1", props.target.name);
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      <Box className="nav_wrapper">
        <Box className="logo">
          <Link name="home" onClick={handleClick1}>
            <Image
              name="home"
              borderRadius="none"
              objectFit="contain"
              src="./img/logo.svg"
              alt="This is Bullish"
            />
          </Link>
        </Box>
        <Box className="pinned-parent">
          <Box className="pinned">
            <Button
              name="about"
              isActive={link === "about" ? true : false}
              onClick={handleClick1}
              variant="outline"
              colorScheme="white"
              border="0"
              outline="0"
              outline-offset="0"
              p="4px 14px 0 13px"
              className={link === "about" ? "active" : ""}
              leftIcon={"+"}
            >
              {"About"}
            </Button>
          </Box>
          <Box className="pinned">
            <Button
              name="contact"
              isActive={link === "contact" ? true : false}
              onClick={handleClick1}
              variant="outline"
              colorScheme="white"
              border="0"
              outline="0"
              outline-offset="0"
              p="4px 14px 0 13px"
              className={link === "contact" ? "active" : ""}
              leftIcon={"+"}
            >
              {"Contact"}
            </Button>
          </Box>
          <Box className="pinned">
            {/*             <Button
              name="contact"
              isActive={link === "contact" ? true : false}
              onClick={handleClick1}
              variant="outline"
              colorScheme="white"
              border="0"
              outline="0"
              outline-offset="0"
              p="4px 14px 0 13px"
              className={link === "contact" ? "active" : ""}
              leftIcon={"+"}
            >
              {"Contact"}
            </Button> */}
          </Box>
          <Box className="pinned">
            {/*             <Button
              name="connect"
              isActive={link === "connect" ? true : false}
              onClick={handleClick1}
              variant="outline"
              border="0"
              outline="0"
              outline-offset="0"
              p="4px 14px 0 13px"
              className={link === "connect" ? "active" : ""}
              leftIcon={"+"}
            >
              {"Portal"}
            </Button> */}
          </Box>
        </Box>
        <Center className={"copyright-wrapper active"}>
          <Text className="copyright">© 2021 This is Bullish</Text>
        </Center>
      </Box>
      <TransitionGroup component={null}>
        {active && (
          <CSSTransition
            in={isOpen}
            onExited={() =>
              transitionLink !== null ? transitionCheck() : false
            }
            classNames={{
              appear: "box-appear",
              appearActive: "box-active-appear",
              appearDone: "box-done-appear",
              enter: "box-enter",
              enterActive: "box-active-enter",
              enterDone: "box-done-enter",
              exit: "box-exit",
              exitActive: "box-active-exit",
              exitDone: "box-done-exit",
            }}
            timeout={200}
          >
            <Box
              className={
                active
                  ? "nav_overlay active " + link + "-overlay " + link
                  : "nav_overlay"
              }
            >
              <span className={"bars"}></span>
              <Center className={"centered"} h="100%">
                <Box className="nav_items">
                  <Box
                    className={
                      active ? "nav_container active" : "nav_container"
                    }
                  >
                    {component}
                  </Box>
                </Box>
              </Center>
            </Box>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
}
