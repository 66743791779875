import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserView, MobileView } from "react-device-detect";
import BackgroundVideo from "./Video/BackgroundVideo";
import Nav from "./Nav/Nav";

import "./App.scss";

function App() {
  const desktopVideoSources = {
    webm: "https://thisisbullish.s3.eu-west-2.amazonaws.com/bullish-astro-desktop.webm", //"https://gateway.pinata.cloud/ipfs/QmNvFVXLc9nRTybGCvP9TdZKRCeVMp1TKiBnPpKCyTrqeW", //"./vid/bullish-astro-desktop.webm",
    mp4: "https://thisisbullish.s3.eu-west-2.amazonaws.com/bullish-astro-desktop.mp4", //"https://gateway.pinata.cloud/ipfs/QmUMtKKcNG2AN9FNpSpdr85dGuTzB1uA4qsopvY4PfW425", //"./vid/bullish-astro-desktop.mp4",
  };
  const mobileVideoSources = {
    webm: "https://thisisbullish.s3.eu-west-2.amazonaws.com/bullish-astro-mobile.webm", //"https://gateway.pinata.cloud/ipfs/QmVvkJbtd5AzFoyDoitZ5HD7YTmyEt3RyQHGTSW16SNJwd", //"./vid/bullish-astro-mobile.webm",
    mp4: "https://thisisbullish.s3.eu-west-2.amazonaws.com/bullish-astro-mobile.mp4", //"https://gateway.pinata.cloud/ipfs/QmNitw4wVubJT7wu9xLnsPrbyZMZ47n34dc2K5KWTKCVDe", //"./vid/bullish-astro-mobile.mp4",
  };
  const posterSource = "./img/poster.png";

  const theme = extendTheme({
    colors: {
      brand: {
        100: "#FFFFFF",
        // ...
        bloodorange: "#FF3C15",
        // ...
        mkblue: "#3820FF",
        bullishgreen: "#36FF1F",
      },
    },
    fonts: {
      body: "'Space Mono', monospace",
      heading: "'Montserrat', sans-serif",
      mono: "'Space Mono', monospace",
    },
    components: {
      Checkbox: {
        baseStyle: {
          bg: "none",
          control: {
            bg: "none",
            borderColor: "#FFFFFF",
            borderWidth: "1px",
            _checked: {
              bg: "#FFFFFF",
              borderColor: "#FFFFFF",
              borderWidth: "1px",
              _hover: {
                bg: "#FFFFFF",
                borderColor: "#FFFFFF",
                borderWidth: "1px",
              },
              _disabled: {
                bg: "none",
                //bg: mode("gray.200", "whiteAlpha.300")(props),
                //color: mode("gray.500", "whiteAlpha.500")(props),
              },
            },
          },
        },
      },
    },
    styles: {
      global: {
        // styles for the `body`
        body: {
          bg: "none",
          lineHeight: "base",
          letterSpacing: "0.06em",
          fontWeight: 100,
          color: "#FFFFFF",
        },
        button: {
          textTransform: "uppercase",
          verticalAlign: "middle",
          display: "inline-flex",
          letterSpacing: "0.09em",
          /* _hover: {
            textDecoration: "none",
          },*/
        },
        input: {
          textTransform: "capitalize",
        },
        textarea: {
          textTransform: "capitalize",
        },
        h1: {
          fontWeight: 100,
          marginBottom: "0.5em",
        },
        p: {
          //textTransform: "uppercase",
        },
        div: {
          //textTransform: "uppercase",
        },
        /*         // styles for the `a`
        a: {
          color: "teal.500",
          _hover: {
            textDecoration: "underline",
          },
        }, */
      },
    },
  });

  return (
    <div className="container" style={{ height: "" }}>
      <ChakraProvider theme={theme}>
        <Nav />
        <BrowserView>
          <BackgroundVideo
            blur={2}
            videoSources={desktopVideoSources}
            posterSource={posterSource}
          ></BackgroundVideo>
        </BrowserView>
        <MobileView>
          <BackgroundVideo
            blur={2}
            videoSources={mobileVideoSources}
            posterSource={posterSource}
          ></BackgroundVideo>
        </MobileView>
      </ChakraProvider>
    </div>
  );
}

export default App;
