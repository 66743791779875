import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useMoralis } from "react-moralis";
import {
  Input,
  Button,
  Text,
  Textarea,
  FormControl,
  FormErrorMessage,
  Box,
  Container,
  HStack,
  Center,
} from "@chakra-ui/react";
import MoonLoader from "../Misc/MoonLoader";
import { FaTelegramPlane, FaDiscord, FaTwitter } from "react-icons/fa";
import { Formik, Field, Form } from "formik";

const ContactForm = () => {
  const { user } = useMoralis();
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: user ? user.attributes.ethAddress : "",
    email: "",
    message: "",
  });
  const nextTick = useEffect;
  const form = useRef();
  let alertMsg;

  if (status) {
    alertMsg = <Box className="chakra-button message success">Sent</Box>;
  } else {
    alertMsg = (
      <Button
        mt="1.5em"
        variant="link"
        colorScheme="white"
        isFullWidth={false}
        isLoading={loading}
        spinner={<MoonLoader />}
        isDisabled={valid}
        type="submit"
        textAlign="center"
      >
        Send
      </Button>
    );
  }

  const handleSubmit = async (e, a) => {
    setLoading(true);

    /*     const { name, email, message } = e;
    let details = {
      name: name ? name : "Anon",
      email: email ? email : "No email",
      message: message ? message : "No message",
    }; */

    emailjs
      .sendForm(
        "service_xpfl528",
        "template_omezyfi",
        form.current,
        "user_7jw7DEeSZojLC9ACIuDoC"
      )
      .then(
        async (result) => {
          setStatus(true);
          // reset
          setValid(false);
          setInitialValues({
            name: user ? user.attributes.ethAddress : " ",
            email: " ",
            message: " ",
          });

          a.resetForm(initialValues);
          setInitialValues({
            name: user ? user.attributes.ethAddress : "",
            email: "",
            message: "",
          });
          setLoading(false);
          //console.log(result.text);

          // delay
          await new Promise((res) => setTimeout(res, 5000));
          setStatus(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  function validate(value) {
    let error;
    if (!value) {
      error = "Your message is important";
    }
    return error;
  }

  function validateName(value) {
    /*     let error;
    if (!value) {
      error = "Please include your name";
    }
    return error; */
  }

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Please include your contact email";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "That's not an email 🧐";
    }
    return error;
  }

  function openLink(_url) {
    var link = ``;

    if (_url === "telegram") {
      link = `https://t.me/thisisbullish`;
    } else if (_url === "twitter") {
      link = `https://twitter.com/thisisbullish`;
    } else if (_url === "discord") {
      link = `https://discord.com/`;
    }
    window.open(link, "_blank");
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          handleSubmit(values, { resetForm });
        }}
      >
        {(props) => (
          <Container className="flex-container">
            <Box className="" pb="">
              <Text as="h1">Work with us. Get in touch.</Text>
            </Box>
            <Box className="flex-child">
              <Form ref={form}>
                <Field name="name" validate={validateName}>
                  {({ field, form }) => (
                    <FormControl
                    //isInvalid={form.errors.name && form.touched.name}
                    >
                      <Input
                        {...field}
                        autoComplete="off"
                        id="name"
                        className="first"
                        placeholder="name"
                        mt={4}
                        borderRadius={1}
                        variant="outline"
                        borderColor="#FFF"
                        borderStyle="solid"
                        isRequired={false}
                      />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email" validate={validateEmail}>
                  {({ field, form }) => (
                    <FormControl
                    //isInvalid={form.errors.email && form.touched.email}
                    >
                      <Input
                        {...field}
                        autoComplete="off"
                        id="email"
                        placeholder="email"
                        mt={4}
                        borderRadius={1}
                        variant="outline"
                        borderColor="#FFF"
                        borderStyle="solid"
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="message" validate={validate}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.message && form.touched.message}
                    >
                      <Textarea
                        {...field}
                        autoComplete="off"
                        id="message"
                        name="message"
                        placeholder="message"
                        mt={4}
                        variant="outline"
                        borderRadius={1}
                        borderColor="#FFF"
                        borderStyle="solid"
                      />
                      <FormErrorMessage>{form.errors.message}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Center w="100%" h="44px">
                  {/*                             {
                              (setTimeout(() => setValid(!props.isValid), 0),
                              alertMsg)
                            } */}
                  {
                    (nextTick(() => {
                      setValid(!props.isValid);
                    }, [props.isValid]),
                    alertMsg)
                  }
                </Center>
              </Form>
            </Box>

            <HStack className="flex-child" spacing=".75em">
              <Button
                variant="outline"
                borderRadius={1}
                colorScheme="brand"
                isFullWidth={false}
                isDisabled={false}
                onClick={() => openLink("telegram")}
              >
                {<FaTelegramPlane />}
              </Button>
              <Button
                variant="outline"
                borderRadius={1}
                colorScheme="brand"
                isFullWidth={false}
                isDisabled={false}
                onClick={() => openLink("twitter")}
              >
                {<FaTwitter />}
              </Button>
              <Button
                variant="outline"
                borderRadius={1}
                colorScheme="brand"
                isFullWidth={false}
                isDisabled={true}
                onClick={() => openLink("discord")}
              >
                {<FaDiscord />}
              </Button>
            </HStack>
          </Container>
        )}
      </Formik>
    </>
  );
};

export default ContactForm;
