import React, { useState, useEffect } from "react";
import { useMoralisDapp } from "../providers/MoralisDappProvider/MoralisDappProvider";
import { useMoralis } from "react-moralis";
import Address from "./Address/Address";
import { SelectOutlined } from "@ant-design/icons";
import { getExplorer } from "../helpers/networks";
import {
  Input,
  Button,
  FormControl,
  FormErrorMessage,
  Box,
  Text,
  Link,
  Checkbox,
  Container,
  VStack,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import MoonLoader from "../Misc/MoonLoader";

const Account = () => {
  const {
    //Moralis,
    isInitialized,
    user,
    //account,
    authenticate,
    isAuthenticated,
    isWeb3Enabled,
    enableWeb3,
    isWeb3EnableLoading,
    logout,
  } = useMoralis();
  const { walletAddress, chainId } = useMoralisDapp();

  let alertMsg;

  // web3
  const default_label = "ϟ Connect";
  const [status, setStatus] = useState(false);
  const [validWeb3, setWeb3Disabled] = useState(true);
  const [label_display, setLabel] = useState(default_label);
  const [initialValues, setInitialValues] = useState({
    email: "",
  });
  // email
  const default_email_label = "Send";
  const [emailStatus, setEmailStatus] = useState(false);
  const [validEmail, setEmailDisabled] = useState(true);
  const [email_label_display, setEmailLabel] = useState(default_email_label);
  const [emailInitialValues, setEmailInitialValues] = useState({
    //toggle: false,
    email: "",
    terms: false,
  });
  // both
  const [emailFormIsValid, setEmailFormIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");

  const [checked, setChecked] = useState(false);

  const nextTick = useEffect;

  useEffect(() => {
    console.log("isWeb3EnableLoading: ", isWeb3EnableLoading);
    console.log("isWeb3Enabled: ", isWeb3Enabled);
    console.log("isAuthenticated: ", isAuthenticated);

    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
      enableWeb3();
      //enableWeb3({ provider: "walletconnect", chainId: 56 });
      console.log("web3 activated");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    isWeb3Enabled,
    isInitialized,
    enableWeb3,
    //validEmail,
    //emailFormIsValid,
  ]);

  /*   document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      window.localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
    }
  }); */

  if (link === "email") {
    if (emailStatus) {
      alertMsg = <Box className="chakra-button message success">Sent</Box>;
    } else {
      alertMsg = (
        <Button
          h="1.75rem"
          variant="link"
          colorScheme="white"
          isFullWidth={false}
          isLoading={loading}
          spinner={<MoonLoader />}
          isDisabled={validEmail}
          type="submit"
          textAlign="center"
        >
          {email_label_display}
        </Button>
      );
    }
  } else {
    if (status) {
      alertMsg = <Box className="chakra-button message success">Connected</Box>;
    } else {
      alertMsg = (
        <Button
          h="1.75rem"
          variant="link"
          colorScheme="white"
          isFullWidth={false}
          isLoading={loading}
          spinner={<MoonLoader />}
          isDisabled={validWeb3}
          type="submit"
          textAlign="center"
        >
          {label_display}
        </Button>
      );
    }
  }

  // form handling
  // email
  const handleSubmit1 = async (e, a) => {
    setEmailDisabled(true);
    setLoading(true);

    const { email, terms } = e;
    let details = {
      email: email,
      terms: terms,
    };

    let response = await fetch("http://localhost:5000/connect", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    // result
    let result = await response.json();
    if (result.status === 200) {
      // reset
      setEmailFormIsValid(false);
      setEmailDisabled(true);
      setEmailInitialValues({
        email: " ",
        terms: false,
      });
      a.resetForm(emailInitialValues);
      setEmailInitialValues({
        email: "",
        terms: false,
      });
      setLoading(false);
      setEmailStatus(true);
      // delay
      await new Promise((res) => setTimeout(res, 5000));
      setEmailStatus(false);
    }
  };

  // form handling
  // web3
  const handleSubmit = async (e, a) => {
    setWeb3Disabled(true);
    setLoading(true);
    // reset
    setStatus(false);

    const { email } = e;
    let details = {
      email: email,
    };

    authenticate({
      signingMessage:
        "By signing I accept the site's Terms and Conditions and Privacy Policy.",
      onSuccess: (_user) => {
        const saveUser = async () => {
          if (_user && details.email) {
            _user.set("email", details.email);
            await _user.save();
          }
          details = null;
        };
        saveUser();
        // reset
        setLoading(false);
        setLabel(default_label);
        setWeb3Disabled(false);
        setStatus(false);
        setEmailFormIsValid(false);
        setInitialValues({
          email: " ",
        });
        a.resetForm(initialValues);
        setInitialValues({
          email: "",
        });
      },
      onError: async (_e) => {
        // feedback
        setStatus(false);
        setWeb3Disabled(true);
        setLoading(false);
        setLabel("Aborted");

        await new Promise((res) => setTimeout(res, 2500));

        // reset
        details = null;
        setLabel(default_label);
        setWeb3Disabled(false);
        setStatus(false);

        if (_e.code === 4001) console.log("Denied");
      },
    });

    /*     logout({
      onSuccess: () => {
        console.log("success");
        console.log("SHOW UNAUTHENTICATED DISPLAY");
      },
      onError: async (_e) => {
        console.log("ERROR");
        console.log("Denied", JSON.stringify(_e));
      },
    }); */
  };
  /* 
  const authWalletConnect = async () => {
    console.log("WALLETCONNECT FUNC");
    //setWeb3Disabled(true);
    // reset
    //setStatus(false);

    const user = authenticate({
      provider: "walletconnect",
      chainId: 56,
      // mobileLinks: [
      //   "metamask",
      //   "trust",
      //   "rainbow",
      //   "argent",
      //   "imtoken",
      //   "pillar",
      // ],
      signingMessage:
        "By signing I accept the site's Terms and Conditions and Privacy Policy.",
      onSuccess: () => {
        enableWeb3({
          provider: "walletconnect",
          chainId: 56,
          onSuccess: () => {
            console.log("SUCCESSSSS!");
          },
        });
      },
    });
    console.log(user);
  };
 */
  // navigation
  const handleFormSwitch = (props) => {
    setLink(props.target.name);

    /*     setInitialValues({
      email: "",
    });
    setEmailInitialValues({
      email: "",
      terms: false,
    }); */

    // reset
    if (loading === true) {
      setLoading(false);
    }

    if ("email" === props.target.name) {
      if (emailFormIsValid && checked) {
        setEmailDisabled(false);
      } else {
        setEmailDisabled(true);
      }
      setEmailLabel(default_email_label);
    } else if ("web3" === props.target.name) {
      setLabel(default_label);
    }
  };

  function validateTerms(value) {
    console.log("SET EMIAL FORM…!");
    setChecked(value);
    let error = "";
    if (!value || value === false) {
      setEmailDisabled(true);
      error = "Accepting terms and privacy policy is required.";
    } else if (emailFormIsValid) {
      console.log("SET EMIAL FORM ENABLED!");
      setEmailDisabled(false);
    }

    return error;
  }

  function validateEmail(value) {
    let error = "";
    console.log(value);
    if (!value) {
      error = "Please include your contact email";
      setEmailFormIsValid(false);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "That's not an email 🧐";
      console.log("NOT AN EMAIL ");
      setEmailFormIsValid(false);
    } else {
      setEmailFormIsValid(true);
    }
    if (link === "web3") {
      if (error !== "") {
        setWeb3Disabled(true);
      } else {
        setWeb3Disabled(false);
      }
    } else if (link === "email") {
      // error given: disable form
      if (error !== "") {
        setWeb3Disabled(true);
        setEmailDisabled(true);
        // checked AND email valid: enable form
      } else if (checked && emailFormIsValid) {
        setEmailDisabled(false);
        // not valid BUT no error: enable form
      } else {
        setEmailDisabled(true);
        setWeb3Disabled(false);
      }
    }

    return error;
  }

  if (!isInitialized) {
    return (
      <>
        <h1 className="error">Connection Error</h1>
      </>
    );
  } else {
    if (!isAuthenticated) {
      if (link === "email") {
        return (
          <Formik
            initialValues={initialValues}
            validateOnMount={true}
            validateOnChange={true}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
              setChecked(false);
              await handleSubmit1(values, { resetForm });
            }}
          >
            {(props) => (
              <Container className="flex-container">
                <Box className="" pb="">
                  <Text as="h1">{"DAO Invite"}</Text>
                </Box>
                <Box className="flex-child divider" pt="2em">
                  <Form>
                    <Field name="email" validate={validateEmail}>
                      {({ field, form }) => (
                        <FormControl>
                          <InputGroup size="md">
                            <Input
                              {...field}
                              id="email"
                              placeholder="email"
                              borderRadius={1}
                              variant="outline"
                              borderColor="#FFF"
                              borderStyle="solid"
                              isRequired={true}
                            />
                            <InputRightElement width="4.5rem">
                              {
                                (nextTick(() => {
                                  // avoid set after unmount
                                  if (props.isValid) {
                                    // set if field enabled/disabled
                                    setEmailDisabled(!props.isValid);
                                  }
                                  return () => {
                                    // clean-up
                                    setEmailDisabled(true);
                                  };
                                }, [props.isValid]),
                                alertMsg)
                              }
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="terms" validate={validateTerms}>
                      {({ field, form }) => (
                        <FormControl>
                          <Box mt="1em">
                            <Checkbox
                              {...field}
                              id="terms"
                              name="terms"
                              borderRadius={1}
                              variant="outline"
                              defaultIsChecked={false}
                              isFocusable={false}
                              isRequired={true}
                              isDisabled={false}
                              isChecked={checked}
                            >
                              <Text as="p" className="">
                                I accept the{" "}
                                <Link href="#" target="_blank" rel="noreferrer">
                                  T&Cs
                                </Link>{" "}
                                and{" "}
                                <Link href="#" target="_blank" rel="noreferrer">
                                  Privacy Policy
                                </Link>
                              </Text>
                            </Checkbox>
                          </Box>
                          <FormErrorMessage>
                            {form.errors.terms}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Form>
                </Box>
                <Box className="" mt="1em">
                  <Text as="p">
                    Connect via a{" "}
                    <Button
                      name="web3"
                      fontSize="brand.100"
                      colorScheme="white"
                      isFullWidth={false}
                      variant="link"
                      isDisabled={false}
                      onClick={handleFormSwitch}
                    >
                      wallet here
                    </Button>
                    .
                  </Text>
                </Box>
              </Container>
            )}
          </Formik>
        );
      } else {
        return (
          <>
            <Formik
              initialValues={initialValues}
              validateOnMount={true}
              validateOnChange={true}
              enableReinitialize={true}
              onSubmit={async (values, { resetForm }) => {
                handleSubmit(values, { resetForm });
              }}
            >
              {(props) => (
                <Container className="flex-container">
                  <Box className="" pb="">
                    <Text as="h1">{"Connect to DAO"}</Text>
                  </Box>
                  <Box className="flex-child divider" pt="2em">
                    <Form>
                      <Field name="email" validate={validateEmail}>
                        {({ field, form }) => (
                          <FormControl>
                            <InputGroup size="md">
                              <Input
                                {...field}
                                id="email"
                                placeholder="email"
                                borderRadius={1}
                                variant="outline"
                                borderColor="#FFF"
                                borderStyle="solid"
                              />
                              <InputRightElement width="4.5rem">
                                {
                                  (nextTick(() => {
                                    // avoid set after unmount
                                    if (props.isValid) {
                                      // set if field enabled/disabled
                                      setWeb3Disabled(!props.isValid);
                                    }
                                    return () => {
                                      // clean-up
                                      setWeb3Disabled(true);
                                    };
                                  }, [props.isValid]),
                                  alertMsg)
                                }
                              </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Form>
                  </Box>
                  <Box className="" mt="1em"></Box>
                  <Box className="" mt="1em">
                    <Text as="p">
                      {/*                       Alterantively use{" "}
                      <Button
                        name="walletconnect"
                        fontSize="brand.100"
                        colorScheme="white"
                        isFullWidth={false}
                        variant="link"
                        isDisabled={false}
                        onClick={() => authWalletConnect()}
                      >
                        <i>Wallet Connect</i>
                      </Button>{" "} */}
                      If you're unable to access a <i>Web3</i> wallet, register
                      for an{" "}
                      <Button
                        name="email"
                        fontSize="brand.100"
                        colorScheme="white"
                        isFullWidth={false}
                        variant="link"
                        isDisabled={false}
                        onClick={handleFormSwitch}
                      >
                        invite here
                      </Button>{" "}
                      in the meantime.
                    </Text>
                  </Box>
                </Container>
              )}
            </Formik>
          </>
        );
      }
    } else {
      return (
        <>
          <VStack>
            <Box>
              <Box className="" pb="">
                <Text as="h1">
                  {user ? user.attributes.username : "This is Bullish"}
                </Text>
              </Box>
              <Box
                className="flex-child"
                style={{ marginTop: "1em", padding: "" }}
              >
                <Address avatar="left" size={12} copyable />
                <a
                  href={`${getExplorer(chainId)}address/${walletAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SelectOutlined
                    style={{
                      marginRight: "5px",
                      top: "-3px",
                      position: "relative",
                    }}
                  />
                  View on Explorer
                </a>
              </Box>
              <Box
                className="flex-child"
                style={{ marginTop: "10px", padding: "0 10px" }}
              >
                <Button
                  mt={4}
                  isFullWidth={false}
                  isDisabled={false}
                  onClick={() => {
                    logout();
                  }}
                  variant="outline"
                  border="0"
                  outline="0"
                  outline-offset="0"
                  p="4px 14px 0 13px"
                >
                  Disconnect
                </Button>
              </Box>
            </Box>
          </VStack>
        </>
      );
    }
  }
};

export default Account;
