import { Text, Container, Box, Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const About = () => {
  return (
    <Container className="flex-child">
      <Box>
        <Text as="p">
          <i>
            <b>bullish</b> adjective{" "}
          </i>{" "}
          (ATTITUDE):
          <br />
          Optimistic or confident.
        </Text>
        <br />
        <Text as="p">
          {"//"} We are creatives that are as bullish about <i>Web3</i> as you.
        </Text>
        <br />
        <Text>
          Read our{" "}
          <Link
            color="brand.100"
            href="https://ipfs.io/ipfs/Qmf4qbdAp47VntiuQY9UqU8eooqqmbpBo67MnLTnEXVeQX/TIB-Whitepaper.pdf"
            isExternal
          >
            [whitepaper
            <ExternalLinkIcon className="ext-icon" mx="2px" />]
          </Link>
          .
        </Text>
      </Box>
    </Container>
  );
};

export default About;
