import React from "react";

const BackgroundVideo = ({ videoSources, posterSource, children, blur }) => {
  // const video = React.useRef(null);
  // React.useEffect(() => {
  //   console.log(video.current.style);
  //   video.current.style.filter = "blur(10px)";
  // }, []);

  /*   let desktopVideo = false;
  let tabletVideo = false;
  let mobileVideo = false;

  const getVideoSrc = (width) => {
    if (width >= 1080) return (desktopVideo = true);
    if (width >= 720) return (tabletVideo = true);
    return (mobileVideo = true);
  }; */

  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  //const [videoPlaying, setVideoPlaying] = React.useState(true);

  //const _isOpen = useContext(isOpen);

  //const src = getVideoSrc(window.innerWidth);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  const onPlaying = () => {
    //console.log("PLAYING");
  };

  const onEnded = () => {
    //console.log("ENDED");
  };

  const onPlay = () => {
    //console.log("ON PLAY");
  };

  //var context = canvas.getContext("2d", { alpha: false });

  /*   var req = new XMLHttpRequest();
  req.open("GET", videoSources, true);
  req.responseType = "blob";

  req.onload = function () {
    // Onload is triggered even on 404
    // so we need to check the status code
    if (this.status === 200) {
      var videoBlob = this.response;
      var vid = URL.createObjectURL(videoBlob); // IE10+
      // Video is now downloaded
      // and we can set it as source on the video element
      video.src = vid;
    }
  };
  req.onerror = function () {
    // Error
  };
  req.send(); */

  const video = React.useRef(null);
  React.useEffect(() => {
    //console.log(video.current.style);
    //video.current.style.filter = "blur(10px)";
    /*     if (_isOpen === true) {
      console.log("OPEN: PAUSE VID");
      video.current.pause();
    } */
  }, []);

  //const [count, setCount] = React.useState(0);

  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  /*   const requestRef = React.useRef();
  const previousTimeRef = React.useRef();
  let modToggle = 0;

  const animate = (time) => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = time - previousTimeRef.current;

      // Pass on a function to the setter of the state
      // to make sure we always have the latest state
      setCount((prevCount) => (prevCount + deltaTime * 0.001) % 100);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []); // Make sure the effect runs only once */

  return (
    <>
      <div className="video-container">
        <img
          src={posterSource}
          className="video-thumb tiny"
          alt="thumb"
          style={{ display: isVideoLoaded ? "none" : "block" }} // { opacity: isVideoLoaded ? 0 : 1 },
        />
        <video
          autoPlay="autoplay"
          loop="loop"
          muted={true}
          playsInline
          ref={video}
          id="video-id"
          className="video"
          alpha="false"
          poster={posterSource}
          onLoadedData={onLoadedData}
          onEnded={onEnded}
          onPlaying={onPlaying}
          onPlay={onPlay}
          //imageSmoothingEnabled={false}
          //progress={progress}

          style={{
            //filter: `blur(${blur}px)`,
            //WebkitFilter: `blur(${blur}px)`,
            opacity: isVideoLoaded ? 1 : 0,
          }}
        >
          {/* TODO make it accept multiple media types */}
          <source src={videoSources.webm} type="video/webm" />
          <source src={videoSources.mp4} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {children}
      </div>
      {/* <span id="video-bottom"></span> */}
    </>
  );
};

export default BackgroundVideo;
